import photo_1 from '../assets/images/photo_1.png';
import photo_2 from '../assets/images/photo_2.png';
import photo_3 from '../assets/images/photo_3.png';

export const person = [
    {
        name: 'Ірина Андрунів',
        img: photo_1,
        comment: 'Була на прийомі у клініці і я дуже рада що обрала саме вашу стоматологію. Дуже гарний підхід до кожної\n' +
            'людини, гарне обслуговування, атмосфера,кваліфіковані лікарі. Доступно пояснюють суть проблеми і план лікування. Я посвністю задоволена 👍',
        date: '17 липня, 2024'
    },
    {
        name: 'Анастасія Чайка',
        img: photo_3,
        comment: 'Лікувала дитині зуби – все чудово. Гарна чиста клініка, привітний персонал, недорогі ціни. Бо обирали довго і ціни в інших клініках трохи завищені, як на мене. А тут і ціни нормальні і лікарі чудові!\n' +
            'Знайшли підхід до моєї дитини і це головне!\n' +
            'Сама теж буду тут лікуватися.',
        date: '6 серпня, 2024'
    },
    {
        name: 'Тарас Вітвіцький',
        img: photo_2,
        comment: 'Щиро дякую за роботу, всім задоволений Привітний персонал, кваліфіковані лікарі, особлива подяка Тарасу Петровичу за професіоналізм. Рекомендую 💯',
        date: '3 вересня, 2024'
    }
];